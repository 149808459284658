class WriteWithAi {
  constructor({ api, config }) {
    this.api = api;
    this.config = config;
    WriteWithAi.isShowAi = config.isShowAi;
  }


    static get toolbox() {
      if(WriteWithAi.isShowAi){
        return {
          title: 'Write With AI',
          icon: '<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.960081 11.7053C1.03956 11.6021 1.10847 11.489 1.19962 11.3975C4.05037 8.54314 6.90367 5.69056 9.75625 2.83799C10.0322 2.56199 10.2488 2.56236 10.5263 2.83981C11.0673 3.3805 11.6084 3.9212 12.1491 4.46262C12.3868 4.7007 12.3882 4.93805 12.1516 5.17503C9.26696 8.06005 6.38231 10.9451 3.49546 13.8283C3.42655 13.8968 3.33577 13.9435 3.25483 14H3.03607C2.92998 13.9176 2.81477 13.845 2.71997 13.7513C2.21282 13.2508 1.70896 12.7469 1.20837 12.2397C1.11467 12.145 1.04211 12.0294 0.959717 11.9233C0.959717 11.8504 0.959717 11.7774 0.959717 11.7045L0.960081 11.7053ZM3.13488 12.9062C5.10623 10.9352 7.08123 8.96023 9.0318 7.01003C8.67523 6.64799 8.30626 6.27355 7.95188 5.9137C5.98599 7.87958 4.01355 9.85203 2.04766 11.8175C2.40824 12.1785 2.77758 12.5482 3.13488 12.9059V12.9062ZM10.1529 3.7338C9.64177 4.24459 9.12295 4.76341 8.6169 5.2691C8.97237 5.62457 9.34171 5.99391 9.69062 6.34319C10.2018 5.83239 10.721 5.31394 11.2277 4.80789C10.8719 4.45241 10.5026 4.08308 10.1529 3.7338ZM6.36882 2.46939C6.66414 2.76325 6.87779 3.11472 7.08451 3.51249C7.45421 2.70455 8.02808 2.13469 8.84221 1.75952C8.02334 1.38618 7.45421 0.811584 7.07904 0C6.70497 0.815594 6.13511 1.38509 5.32827 1.75661C5.72932 1.96224 6.07568 2.17771 6.36882 2.46939ZM12.2253 0.780958C12.0105 0.995338 11.7418 1.15503 11.487 1.34608C12.0594 1.60311 12.4805 2.03662 12.7649 2.64366C13.0482 2.02823 13.4729 1.60275 14.042 1.34571C13.7886 1.15722 13.5221 1.00117 13.3107 0.790073C13.0952 0.574963 12.934 0.305893 12.7623 0.0780229C12.5946 0.302247 12.4375 0.569129 12.2253 0.780958ZM14.0738 6.12297C13.8823 6.53861 13.5994 6.81533 13.1936 7.00565C13.6056 7.19816 13.8827 7.48108 14.0749 7.88396C14.2681 7.4778 14.5485 7.19779 14.9604 7.00383C14.5401 6.81315 14.2655 6.52803 14.0738 6.12297Z" fill="white"/></svg>'
        };
      }else{
        return null
      }
    }
  
    render() {
      const paragraph = document.createElement('p');
      if (this.config.openSidebar) {
        this.config.openSidebar();
      }
      return paragraph;
    }

    static get isReadOnlySupported(){
      return true
    }
  }

  export default WriteWithAi;